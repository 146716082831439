import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
import ComplaintsComplianceNav from '../../../components/SubPageNav/ComplaintsComlianceNav';
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav';

const BuilderComplaintsPage = () => {
	const intl = useIntl();
	return (
		<Layout
			view='builder'
			breadcrumb={{
				text: <FormattedMessage id='builderNav.dashboard' />,
				path: '/builder-vendor/dashboard/'
			}}
			header={<FormattedMessage id='builderNav.complaints' />}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'builderNav.complaints' })} />
			<Container>
				<Row>
					<Col lg={3} md={3}>
						<ComplaintsComplianceNav activePath='/builder-vendor/complaints-compliance-enforcement/complaints/' />
					</Col>

					<Col>
						<Row>
							<Col>
								<h2>
									<FormattedMessage id='builderComplaints1.headerA' />
								</h2>
								<p>
									<FormattedMessage id='builderComplaints1.contentAi' />
								</p>

								<p>
									<FormattedMessage id='builderComplaints1.contentAii' />
								</p>

								<ul>
									<li>
										<strong>
											<FormattedMessage id='builderComplaints1.list01A_B' />
										</strong>{' '}
										– <FormattedMessage id='builderComplaints1.list01A' />
									</li>
									<li>
										<strong>
											<FormattedMessage id='builderComplaints1.list01B_B' />
										</strong>{' '}
										– <FormattedMessage id='builderComplaints1.list01B' />
									</li>
									<li>
										<strong>
											<FormattedMessage id='builderComplaints1.list01C_B' />
										</strong>{' '}
										– <FormattedMessage id='builderComplaints1.list01C' />
									</li>
									<li>
										<strong>
											<FormattedMessage id='builderComplaints1.list01D_B' />
										</strong>{' '}
										– <FormattedMessage id='builderComplaints1.list01D' />
									</li>
								</ul>

								<p>
									<FormattedMessage id='builderComplaints1.contentAiii' />
								</p>

								<ul>
									<li>
										<FormattedMessage id='builderComplaints1.list02A' />
									</li>
									<li>
										<FormattedMessage id='builderComplaints1.list02B' />
									</li>
									<li>
										<FormattedMessage id='builderComplaints1.list02C' />
									</li>
									<li>
										<FormattedMessage id='builderComplaints1.list02D' />
									</li>
								</ul>

								<p>
									<FormattedMessage id='builderComplaints1.contentAiv' />
								</p>
							</Col>
						</Row>

						<Row className='pageRow'>
							<Col>
								<h2>
									<FormattedMessage id='builderComplaints1.headerB' />
								</h2>
								<p>
									<FormattedMessage id='builderComplaints1.contentBi' />
								</p>
								<ul>
									<li>
										<FormattedMessage id='builderComplaints1.list03A' />
									</li>
									<li>
										<FormattedMessage id='builderComplaints1.list03B' />
									</li>
									<li>
										<FormattedMessage id='builderComplaints1.list03C' />
									</li>
									<li>
										<FormattedMessage id='builderComplaints1.list03D' />
									</li>
								</ul>
								<p>
									<FormattedMessage id='builderComplaints1.contentBii' />
								</p>
							</Col>
						</Row>

						<Row className='pageRow'>
							<Col>
								<h2>
									<FormattedMessage id='builderComplaints1.headerC' />
								</h2>
								<p>
									<FormattedMessage id='builderComplaints1.contentCi' />
								</p>
								<p>
									<FormattedMessage id='builderComplaints1.contentCii' />
								</p>
								<p>
									<FormattedMessage id='builderComplaints1.contentCiii' />
								</p>
								<p>
									<FormattedMessage id='builderComplaints1.contentCiv' />
								</p>

								<ul>
									<li>
										<FormattedMessage id='builderComplaints1.list04A' />
									</li>
									<li>
										<FormattedMessage id='builderComplaints1.list04B' />
									</li>
									<li>
										<FormattedMessage id='builderComplaints1.list04C' />
									</li>
									<li>
										<FormattedMessage id='builderComplaints1.list04D' />
									</li>
									<li>
										<FormattedMessage id='builderComplaints1.list04E' />
									</li>
								</ul>

								<p>
									<FormattedMessage id='builderComplaints1.contentCv' />
								</p>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col>
						<SubPageBottomNav
							nextLink={{
								text: <FormattedMessage id='builderComplaints4.headerA' />,
								path: '/builder-vendor/complaints-compliance-enforcement/potential-registrar-actions/'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default BuilderComplaintsPage;
